import { Box, Center, Flex, Heading, HStack, Spacer, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ColorModeSwitcher from '../components/color-mode-switcher';
import LangSwitcher from '../components/lang-switcher';
import packageJson from '../../../package.json';
import { selectIsDev } from '../features/application/application-slice';

function Welcome() {
  const isDev = useSelector(selectIsDev);
  const { t } = useTranslation();
  return (
    <Box h="100vh" overflow="hidden">
      <HStack pt="2px" pr="2px">
        <Spacer />
        <ColorModeSwitcher />
        <LangSwitcher />
      </HStack>
      <Center h="calc(100% - 80px)">
        <VStack w="100%">
          <Heading size="md">{t('pages.welcome.underConstruction', 'Site is under construction')}</Heading>
          <Heading size="md">{t('pages.welcome.thankYouForPatience', 'thank you for you patience')}</Heading>
        </VStack>
      </Center>
      <Flex h="40px" alignItems="flex-end">
        <Text color={useColorModeValue('brand.100', 'brand.800')} fontSize="xs" ml="3" mb="2">
          {`V${packageJson.version}`} {isDev ? 'DEV' : 'PROD'}
          {' MODE'}
        </Text>
      </Flex>
    </Box>
  );
}

export default Welcome;
