const translations = {
  components: {
    lang_switcher: {
      en_tooltip: 'angielski ',
      pl_tooltip: 'polski',
    },
    color_mode_switcher: {
      light_tooltip: 'jasny',
      dark_tooltip: 'ciemny',
    },
    contentLoading: {
      loadingText: 'Ładowanie...',
    },
    refetchButton: { tooltip: 'Odśwież' },
    errorInfo: {
      errorTitle: 'Błąd',
    },
    not_found: {
      title: 'Przykro nam, nie znaleziono strony!',
      content: 'Strona, której szukasz, mogła zostać usunięta, zmieniła się jej nazwa, lub jest tymczasowo niedostępna',
      backToMain: 'Wróć do strony głównej',
    },
  },
  pages: {
    welcome: {
      underConstruction: 'Strona w budowie',
      thankYouForPatience: 'Dziękujemy za Twoją cierpliwość',
      headerSecondLineAccent: 'z szybkością',
    },
  },
  features: {
    feedback: {
      toast: {
        infoTitle: 'Informacja',
        warningTitle: 'Ostrzeżenie',
        successTitle: 'Sukces',
        errorTitle: 'Błąd',
        loadingTitle: 'Ładowanie...',
        notImplementedTitle: 'Brak implementacji',
        notImplementedDescription: 'Wskazana funkcjonalność nie jest dostępna w aktualnej wersji oprogramowania',
      },
    },
  },
};

export default translations;
