import { Routes, Route } from 'react-router-dom';
import NotFound from '../components/not-found';
import Welcome from './welcome';

function IndexPage() {
  return (
    <Routes>
      <Route index element={<Welcome />} />
      <Route path="*" element={<NotFound h="100vh" backTo="/" />} />
    </Routes>
  );
}

export default IndexPage;
