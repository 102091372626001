const setStringValue = (dotEnvName, defaultStringValue) => process.env[dotEnvName] || defaultStringValue;

const setNumberValue = (dotEnvName, defaultNumberValue) => {
  const value = process.env[dotEnvName];
  const numberValue = Number(value);
  return value == null || value === '' || Number.isNaN(value) || Number.isNaN(numberValue)
    ? defaultNumberValue
    : numberValue;
};

const setBoolValue = (dotEnvName, defaultBoolValue) => {
  const value = process.env[dotEnvName];
  const boolTrue = String(value).toLowerCase() === 'true';
  const boolFalse = String(value).toLowerCase() === 'false';

  return boolTrue || boolFalse ? !!boolTrue : defaultBoolValue;
};

class Wrapper {
  addBoolean = (dotEnvName, defaultValue) => {
    this[dotEnvName] = setBoolValue(dotEnvName, defaultValue);
  };

  addString = (dotEnvName, defaultValue) => {
    this[dotEnvName] = setStringValue(dotEnvName, defaultValue);
  };

  addNumber = (dotEnvName, defaultValue) => {
    this[dotEnvName] = setNumberValue(dotEnvName, defaultValue);
  };
}

const environment = new Wrapper();

environment.addNumber('PORT', 4000);
environment.addNumber('REACT_APP_API_CLIENT_TIMEOUT', 30000);
environment.addString('REACT_APP_USED_SERVER_API_VERSION', 'v1');
environment.addBoolean('REACT_APP_UNLOAD_EMPTY_DYNAMIC_REDUCERS', false);

const env = {
  PORT: environment.PORT,
  API_CLIENT_TIMEOUT: environment.REACT_APP_API_CLIENT_TIMEOUT,
  USED_SERVER_API_VERSION: environment.REACT_APP_USED_SERVER_API_VERSION,
  UNLOAD_EMPTY_DYNAMIC_REDUCERS: environment.REACT_APP_UNLOAD_EMPTY_DYNAMIC_REDUCERS,
};

export { environment };
export default env;
